import {WixEventsWeb} from '@wix/ambassador-wix-events-web/http'
import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {
  EVENTS_APP_ID,
  EVENTS_WEB_API_URL,
  GenericAPI,
  getAmbassadorHeaders,
  addQueryParams,
} from '@wix/wix-events-commons-statics'
import type {ControllerParams} from 'yoshi-flow-editor-runtime'
import {Event} from '@wix/ambassador-wix-events-web/types'
import {ScheduleResponse} from '../types'

export const createApi = (controllerParams: ControllerParams) => {
  const {flowAPI} = controllerParams
  let authorization = flowAPI.controllerConfig.appParams.instance
  const api = new GenericAPI(controllerParams.controllerConfig.appParams.baseUrls.baseUrl, () => getHeaders())

  const getHeaders = () => {
    const headers = {
      Authorization: authorization,
      'Content-Type': 'application/json',
    }

    return headers
  }

  flowAPI.controllerConfig.wixCodeApi.site.onInstanceChanged(({instance}) => {
    authorization = instance
  }, EVENTS_APP_ID)

  const baseUrl = getBaseUrl(controllerParams)

  const wixEvents = WixEventsWeb(`${baseUrl}${EVENTS_WEB_API_URL}`)

  return {
    getEventBySlug: async (slug: string) =>
      (await wixEvents.EventManagement()(getAmbassadorHeaders(authorization)).get({slug})).event,
    getEvent: async () =>
      (await wixEvents.EventManagement()(getAmbassadorHeaders(authorization)).list({limit: 1, offset: 0})).events[0],
    getDemoEvent: (timeZoneId: string, language: string): Promise<Event> =>
      api.get(`/web/demo/event?tz=${timeZoneId}&lang=${language}`),
    getSchedule: (eventId: string, location: string, tags: string[], locale: string): Promise<ScheduleResponse> =>
      api.get(
        addQueryParams(`/web/events/${eventId}/schedule-items`, {
          stageName: location ? [location] : undefined,
          tag: tags.length ? tags : undefined,
          locale,
        }),
      ),
    getDemoSchedule: (timeZoneId: string, language: string): Promise<ScheduleResponse> =>
      api.get(`/web/demo/schedule?tz=${timeZoneId}&lang=${language}`),
  }
}

export const getBaseUrl = (controllerParams: ControllerParams, fallback = '') => {
  if (controllerParams.flowAPI.isSSR()) {
    return parseBaseUrl(controllerParams.controllerConfig.wixCodeApi).root
  } else {
    return fallback
  }
}

export const parseBaseUrl = (wixCodeApi: IWixAPI) => {
  const baseUrl = wixCodeApi.location.baseUrl
  const baseUrlParts = baseUrl.split('/')
  return {
    root: baseUrlParts.slice(0, 3).join('/'),
    relative: baseUrlParts.slice(3).join('/'),
  }
}

export type Api = ReturnType<typeof createApi>
