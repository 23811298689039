import {BI_ENDPOINTS, EventMap, getEventId} from '@wix/wix-events-commons-statics'
import type {AnyAction} from 'redux'
import type {State} from '../reducers'
import {schedulePageLoaded} from '../actions/page-loaded'
import {setLocation, setFilter, toggleTag} from '../actions/filter'
import {readDescriptionClicked} from '../actions/schedule'

export const eventMap: EventMap = {
  [schedulePageLoaded.toString()]: (state: State, action: AnyAction) => {
    if (schedulePageLoaded.match(action)) {
      return {
        evid: 191,
        event_id: getEventId(state.event),
        schedule_item_count: action.payload.totalScheduleItems,
      }
    }
  },
  [setLocation.fulfilled.toString()]: (state: State, action: AnyAction) => {
    if (setLocation.fulfilled.match(action)) {
      return {
        evid: 192,
        event_id: getEventId(state.event),
        is_place_filtered: true,
      }
    }
  },
  [toggleTag.fulfilled.toString()]: (state: State, action: AnyAction) => {
    if (toggleTag.fulfilled.match(action)) {
      return {
        evid: 192,
        event_id: getEventId(state.event),
        is_tags_filtered: true,
      }
    }
  },
  [readDescriptionClicked.toString()]: (state: State, action: AnyAction) => {
    if (readDescriptionClicked.match(action)) {
      return {
        evid: 194,
        event_id: getEventId(state.event),
        schedule_item_id: action.payload.itemId,
      }
    }
  },
  [setFilter.fulfilled.toString()]: (state: State, action: AnyAction) => {
    if (setFilter.fulfilled.match(action)) {
      const {tags, location} = action.meta.arg.data

      const is_place_filtered = Boolean(location)
      const is_tags_filtered = Boolean(Object.values(tags).filter(Boolean).length)

      return {
        evid: 192,
        event_id: getEventId(state.event),
        is_place_filtered,
        is_tags_filtered,
      }
    }
  },
  endpoint: BI_ENDPOINTS.EVENTS_UOU,
}
