import {createAsyncThunk} from '@reduxjs/toolkit'
import {Event} from '@wix/ambassador-wix-events-web/types'
import {getSlug} from '../services/location'
import {ThunkConfig} from './interfaces'

export const getEvent = createAsyncThunk<Event, undefined, ThunkConfig>(
  'GET_EVENT',
  (args, {extra: {api, controllerParams}}) => {
    const slug = getSlug(controllerParams)
    return slug ? api.getEventBySlug(slug).catch(() => api.getEvent()) : api.getEvent()
  },
)

export const getDemoEvent = createAsyncThunk<Event, {timeZoneId: string; language: string}, ThunkConfig>(
  'GET_DEMO_EVENT',
  async ({timeZoneId, language}, {extra: {api}}) => api.getDemoEvent(timeZoneId, language),
)
